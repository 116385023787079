import apiServices from "../../service";
import { isObjectEmpty } from "../../utils";
import {
  AdministrationKeyMapping,
  transformKeys,
} from "../../utils/DataKeyMapping";

export const getCustomerReview = async (customerId: string) => {
  try {
    const response = await apiServices.client.getDetailByTab(
      customerId,
      "administration"
    );
    if (
      response.data &&
      !response.data.isError &&
      !isObjectEmpty(response.data.data)
    ) {
      const transformedData = transformKeys(
        response.data.data,
        AdministrationKeyMapping
      );
      return transformedData[0];
    }
    return {};
  } catch (err) {
    console.log(err);
    return {};
  }
};
