import * as React from "react";
import {
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding("16px"),
    borderRadius: tokens.borderRadiusMedium,
  },
  row: {
    alignItems: "center",
    display: "grid",
    paddingBottom: tokens.spacingVerticalS,
    position: "relative",
    ...shorthands.gap(tokens.spacingHorizontalS),
    rowGap: "18px",
    gridTemplateColumns: "min-content repeat(5, 1fr)", // Adjust based on the number of columns
  },
  rowHeader: {
    // backgroundColor: tokens.colorNeutralBackground2,
    // padding: tokens.spacingVerticalS,
    // borderRadius: tokens.borderRadiusSmall,
  },
});

export const TableListLoader = ({
  rows = 6, // Number of rows
  columns = 5, // Number of columns
  ...props
}: SkeletonProps & { rows?: number; columns?: number }) => {
  const styles = useStyles();

  return (
    <div className={styles.invertedWrapper}>
      <Skeleton {...props}>
        {/* Header row */}
        <div className={`${styles.row} ${styles.rowHeader}`}>
          {Array.from({ length: columns }).map((_, index) => (
            <SkeletonItem key={`header-${index}`} shape="rectangle" size={20} />
          ))}
        </div>
        {/* Content rows */}
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <div className={styles.row} key={`row-${rowIndex}`}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <SkeletonItem
                key={`row-${rowIndex}-col-${colIndex}`}
                shape="rectangle"
                size={20}
              />
            ))}
          </div>
        ))}
      </Skeleton>
    </div>
  );
};
