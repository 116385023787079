import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

const initialState = {
  breadcrumbItems: [],
  loginSessionExpiry: false,
  userPermissionDenied: false,
  reloadDataList: false,
  reloadDetails: false,
  actionListSelectedItem: {},
  actionListDetailPopup: false,
};
export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    addBreadcrumbs: (state, action) => {
      state.breadcrumbItems = action.payload;
    },
    loginSessionExpired: (state, action) => {
      state.loginSessionExpiry = action.payload;
    },
    accessDenied: (state, action) => {
      state.userPermissionDenied = action.payload;
    },
    reloadData: (state, action) => {
      state.reloadDataList = action.payload;
    },
    reloadDetails: (state, action) => {
      state.reloadDetails = action.payload;
    },
    setActionListSelectedItem: (state, action) => {
      state.actionListSelectedItem = action.payload;
    },
    openActionListDetailPopup: (state, action) => {
      state.actionListDetailPopup = action.payload;
    },
  },
});

export const {
  addBreadcrumbs,
  loginSessionExpired,
  accessDenied,
  reloadData,
  setActionListSelectedItem,
  openActionListDetailPopup,
  reloadDetails,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;

export const seesionExpired = (status: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginSessionExpired(status));
  };
};
