import React from "react";
import { Spinner } from "@fluentui/react-components";

const ContentLoader = ({ message }: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 80px)",
      }}
    >
      <Spinner
        size="huge"
        labelPosition="below"
        label={message ? message : "Loading..."}
      />
    </div>
  );
};

export default ContentLoader;
