export const EmploymentKeyMapping: Record<string, string> = {
  CustomerId: "customer_id",
  EmploymentId: "employment_id",
  PrincipalEmployment: "principal_employment",
  Occupation: "occupation",
  EmploymentType: "employment_type",
  EmployerName: "employer_name",
  Address1: "address_1",
  Address2: "address_2",
  Address3: "address_3",
  Town: "town",
  County: "county",
  Country: "country",
  Postcode: "postcode",
  Phone: "phone",
  Fax: "fax",
  StartDate: "start_date",
  EndDate: "end_date",
  SalaryReviewDate: "salary_review_date",
  PayrollId: "payroll_id",
  Position: "position",
  ControllingDirector: "controlling_director",
  Shareholding: "shareholding",
  NoteText: "note_text",
  EmploymentBasis: "employment_basis",
  RetirementAge: "retirement_age",
  DeathInServiceYN: "death_in_service_yn",
  DeathInService: "death_in_service",
  SickPayYN: "sick_pay_yn",
  SickPay: "sick_pay",
  PMIYN: "pmi_yn",
  PMI: "pmi",
  PHIYN: "phi_yn",
  PHI: "phi",
  CriticalIllnessYN: "critical_illness_yn",
  CriticalIllness: "critical_illness",
  CompanyCarYN: "company_car_yn",
  CompanyCar: "company_car",
  ShareOptionsYN: "share_options_yn",
  ShareOptions: "share_options",
  OtherYN: "other_yn",
  Other: "other",
  PensionYN: "pension_yn",
  Pension: "pension",
  AutoEnrolment: "auto_enrolment",
};
export const IdentityKeyMapping: Record<string, string> = {
  Reference: "reference",
  IdentityId: "identity_id",
  CustomerId: "customer_id",
  Category: "category",
  Item: "item",
  Issued: "issued",
  Expires: "expires",
  Seen: "seen",
  IssuedAt: "issued_at",
  PlaceOfBirth: "place_of_birth",
  CertifiedCopy: "certified_copy",
  Type: "type",
  AddressCurrent: "address_current",
  ModifiedBy: "modified_by",
  Modified: "modified",
};
export const ObjectiveKeyMapping: Record<string, string> = {
  ObjectiveId: "objective_id",
  CustomerId: "customer_id",
  Category: "category",
  SortOrder: "sort_order",
  ObjectiveDescription: "objective_description",
  Applicable: "applicable",
  Comments: "comments",
  Rating: "rating",
  CreatedBy: "created_by",
  UpdatedBy: "updated_by",
  IsDelete: "is_delete",
};
export const ServiceTypeKeyMapping: Record<string, string> = {
  ServiceTypeId: "servicetype_id",
  CustomerId: "customer_id",
  Description: "description",
  StartDate: "start_date",
  EndDate: "end_date",
  ModifiedBy: "modified_by",
  Modified: "modified",
  VersionId: "version_id",
  CreatedBy: "created_by",
  UpdatedBy: "updated_by",
  IsDelete: "is_delete",
};
export const CustomerDetailsKeyMapping: Record<string, any> = {
  LastName: "last_name",
  FirstNames: "first_names",
  Address2: "address_2",
  Address1: "address_1",
  Address3: "address_3",
  Postcode: "postcode",
  KnownAs: "known_as",
  FormerLastName: "former_last_name",
  CustomerId: "customer_id",
  Title: "title",
  Initials: "initials",
  Honours: "honours",
  AddressName: "address_name",
  Salutation: "salutation",
  JointSalutation: "joint_salutation",
  JointAddressLabel: "joint_address_label",
  Town: "town",
  Country: "country",
  County: "county",
  AssociationType: "associated_type",
  AssociatedWith: "associated_with",
};
export const AdministrationKeyMapping: Record<string, any> = {
  Consultant: "consultant",
  ConsultantId: "consultant_id",
  IntroducedBy: "introduced_by",
  ReviewInterval: "review_interval",
  ReviewDate: "review_date",
  ReviewNote: "review note",
  ReviewAssigned: "review_assigned",
  ReviewCompleted: "review_completed",
  HeadlineNotePopOut: "headeline_not_popout",
  ClientType: "client_type",
  FSAClientType: "fsac_client_type",
  ClientReference: "client_reference",
  NoteText: "note_text",
  ClientSince: "client_since",
  Pending: "pending",
};
export const transformKeys = (
  data: any[],
  keyMapping: Record<string, string>
): any[] => {
  return data.map((item) => {
    const transformedItem: Record<string, any> = {};

    Object.entries(item).forEach(([key, value]) => {
      const newKey = keyMapping[key] || key;
      transformedItem[newKey] = value;
    });

    return transformedItem;
  });
};
