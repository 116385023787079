import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import ASCButton from "../../components/Button";
import Search from "../../components/Search";
import { addBreadcrumbs } from "../../redux/appSettingsSlice";
import DropDownBox from "../../components/DropDownBox";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";

import {
  FullScreenMaximize16Regular,
  FullScreenMinimize16Regular,
  Dismiss16Regular,
  Eye16Filled,
} from "@fluentui/react-icons";
import ClientDataList from "./ClientDataList";
import { AddRegular, ChevronDownRegular } from "@fluentui/react-icons";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuDivider,
  MenuItem,
  MenuList,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  Input,
  Checkbox,
} from "@fluentui/react-components";
import {
  Button,
  Toolbar,
  ToolbarGroup,
  ToolbarButton,
  makeStyles,
} from "@fluentui/react-components";
import ClientActionPage from "./ClientActionPage";
import ModalDialog from "../../components/ModalDialog";
import { fetchSettings } from "../../redux/formSlice";
import { checkUserPermission, convertStringToArray } from "../../utils";
import { UserPermissionsList } from "../../config";
import OverlayLoader from "../../components/OverlayLoader";
import apiServices from "../../service";
import { ExtractMain } from "../utilities/ExtractLocate/extractMain";
import { setReloadClientList } from "../../redux/formUpdateReducer";
import { SkeletonLoader } from "../../components/SkeletonLoader";

import MainTab from "./SavedSearch/MainTab";
import ASCDropDown from "../../components/DropDown";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import { setPartnerIDs } from "../../redux/clientSlice";

interface PropsType {
  setTabs?: Dispatch<SetStateAction<string>>;
  [x: string]: any;
}
interface OptionType {
  label: string;
  value: string;
}
const useStyles = makeStyles({
  toolbar: {
    justifyContent: "space-between",
  },
  fullWidthItem: {
    width: "100%",
    justifyContent: "flex-start",
    backgroundColor: "#FAFAFF",
    borderRadius: "3px",
    height: "25px",
    alignItems: "center",
    display: "flex",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "160px",
    overflowX: "auto",
    padding: "2px",
    gap: "3px",
  },
});
const scopeData: {
  value: any;
  label: string;
}[] = [
  {
    value: "client",
    label: "Client",
  },
  {
    value: "partner",
    label: "Partner",
  },
];
enum TabValues {
  ExportByCondition = "tab1",
  ExportBySelection = "tab2",
}

const List = ({ setTabs, module, formAction }: PropsType) => {
  const [selectedTab, setSelectedTab] = useState<TabValues>(
    TabValues.ExportByCondition
  );
  const [extract, setExtract] = useState<{ [key: string]: any }[]>([]);
  const naviagate = useNavigate();
  const [selectedSearches, setSelectedSearches] = useState<string[]>([]);
  const styles = useStyles();
  const [typeOfExtract, setTypeOfExtract] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [page, setPage] = useState("listing");
  const [leftTabMenu, setLeftTabmenu] = useState("Client");
  const [clientFormSubmited, setClientFormSubmitted] = useState(false);
  const [drawerSize, setDrawerSize] = useState("small");
  const [activeClient, setActiveClient] = useState<{ [key: string]: any }>({});
  const [activeCustomerId, setActiveCustomerId] = useState<string | null>(null);
  const [activePartnerId, setActivePartnerId] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [selectedScope, setSelectedScope] = useState<any>([]);
  const [clientTypeList, setClientTypeList] = useState<any>([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedClientTypeMenu, setSelectedClientTypeMenu] =
    useState("ClientType");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [handleCondition, setHandleCondition] = useState([]);
  const [searchIds, setSearchIds] = useState<any>("");
  const [breadcrumbItem, setBreadcrumbItem] = useState([
    {
      key: 0,
      value: "Home",
      link: "/",
    },
    {
      key: 1,
      value: "Clients",
    },
  ]);
  const handleAddSearchClick = () => {
    setIsDialogOpen(true);
  };
  // const handleMenuItemClick = (text: any) => {
  //   if (text == "") {
  //     setSelectedMenuItem("Saved Searches");
  //   } else {
  //     setSelectedMenuItem(text);
  //   }
  // };
  // const getExtracts = async () => {
  //   setIsLoadingSkeleton(true);
  //   try {
  //     const response = await apiServices.extract.getExtract();
  //     if (!response.data.isError && response.data) {
  //       const filteredData = response.data.data.filter(
  //         (item: any) => item.Type === "Condition"
  //       );

  //       const modifiedData = [
  //         {
  //           SearchId: "",
  //           Title: "",
  //           Description: "",
  //           Condition: "",
  //           UserId: null,
  //           Type: "Extract",
  //         },
  //         ...filteredData,
  //       ];

  //       setExtract(modifiedData);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setIsLoadingSkeleton(false);
  //   }
  // };
  // useEffect(()=>{
  //   getExtracts()
  // },[])
  const handleMenuItemClick = (
    title: string,
    searchId: string,
    type: string
  ) => {
    setSelectedMenuItem((prev) => {
      if (prev.includes(title)) {
        return prev.filter((item) => item !== title);
      } else {
        return [...prev, title];
      }
    });

    setSelectedSearches((prev) => {
      if (prev.includes(searchId)) {
        return prev.filter((id) => id !== searchId);
      } else {
        return [...prev, searchId];
      }
    });

    setTypeOfExtract((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const renderedMenuItems = extract.map((item: any, index: number) => {
    const isSelected = selectedSearches.includes(item.SearchId);

    const handleCheckboxChange = (checked: boolean) => {
      if (checked) {
        setSelectedSearches([...selectedSearches, item.SearchId]);
      } else {
        setSelectedSearches(
          selectedSearches.filter((id) => id !== item.SearchId)
        );
      }
    };

    return (
      <div className={styles.fullWidthContainer} key={item.SearchId}>
        <div
          style={{
            minHeight: "30px",
            backgroundColor: isSelected ? "#e0f7fa" : "transparent",
            cursor: "pointer",
          }}
          className={styles.fullWidthItem}
          onClick={() =>
            handleMenuItemClick(item.Title, item.SearchId, item.Type)
          }
        >
          <Checkbox
            size="medium"
            checked={isSelected}
            onChange={(e) => handleCheckboxChange(e.target.checked)}
          />
          <span
            style={{
              paddingInline: "5px",
            }}
          >
            {item.Title}
          </span>
        </div>
      </div>
    );
  });

  const isFormDataUpdated: boolean = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const isFormupdated = useSelector(
    (state: RootState) => state.formUpdated?.isFormUpdated
  );
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const dispatch: AppDispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     addBreadcrumbs([
  //       {
  //         key: 0,
  //         value: "Home",
  //         link: "/",
  //       },
  //       {
  //         key: 1,
  //         value: "Clients",
  //       },
  //     ])
  //   );
  // }, [dispatch]);

  useEffect(() => {
    setSelectedScope([]);
  }, [search]);

  // useEffect(() => {
  //   if (setTabs) {
  //     setTabs("clients");
  //   }
  //   getClientTypeListOptions();
  // }, []);
  const changePageMode = (mode: string) => {
    setPage(mode);
    setIsOpen(true);
  };
  const setActiveItem = (item: any) => {
    setActiveClient(item);
    setActiveCustomerId(item.customer_id);
    setActivePartnerId(item.NameAndAddress.associated_with);
  };
  const closePage = () => {
    setIsOpen(false);
    setActiveCustomerId(null);
    dispatch(setReloadClientList());
    setPage("listing");
  };
  const setFormUpdated = (val: boolean) => {
    setFormDataUpdated(val);
  };
  const getList = () => {
    setReloadList(true);
  };
  const setDialogModal = (options: any) => {
    setDialogModalOptions(options);
  };
  const resetClientFormSubmited = () => {
    setClientFormSubmitted(false);
  };

  const handleDropdownOpen = (open: any) => {
    console.log("Dropdown open state:", open);
    setIsDropdownOpen(open); // Update your state
    if (open) {
      getClientTypeListOptions(); // Fetch your options when opened
      console.log("Dropdown opened, fetching options.");
    } else {
      console.log("Dropdown closed.");
    }
  };

  const getClientTypeListOptions = async () => {
    setIsLoadingSkeleton(true); // Start the loader before the API call
    const fieldLbl = "client_type:ModifiableList";
    try {
      const res = await apiServices.selectionList.getOptions(fieldLbl);
      if (res.data && res.data.data && res.data.data.length > 0) {
        const emptyOption = { value: "", label: "" };
        setClientTypeList([emptyOption, ...res.data.data[0].options]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingSkeleton(false); // Stop the loader after the API call is done
    }
  };

  const renderPage = (pageView: string) => {
    return (
      <ClientActionPage
        mode={pageView}
        changeMode={changePageMode}
        activeClient={activeClient}
        closePage={closePage}
        activeCustomerId={activeCustomerId}
        activePartnerId={activePartnerId}
        setActivePartnerId={setActivePartnerId}
        setActiveItem={setActiveItem}
        getList={getList}
        dialogModalOptions={dialogModalOptions}
        setDialogModal={setDialogModal}
        clientFormSubmited={clientFormSubmited}
        resetClientFormSubmited={resetClientFormSubmited}
        setBreadcrumbItem={setBreadcrumbItem}
        leftTabMenu={leftTabMenu}
        module={module}
      />
    );
  };
  const handleCloseAction = () => {
    if (isFormDataUpdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
              naviagate(`/`);
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else if (isFormupdated) {
      dispatch(
        setDialogModalOptions({
          open: true,
          content_line_1:
            "We have detected an attempt to move away from the current page",
          content_line_2: "This would cause all changes made to be lost",
          cancel: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
              setPage("listing");
              setActiveCustomerId(null);
              setIsOpen(false);
              setClientFormSubmitted(false);
              dispatch(resetFormData());
              naviagate(`/`);
            },
            label: "Leave the Page",
          },
          no: {
            onclick: () => {
              dispatch(setDialogModalOptions({ open: false }));
            },
            label: "Stay on Page",
            type: "primary",
          },
        })
      );
    } else {
      setPage("listing");
      setActiveCustomerId(null);
      setIsOpen(false);
      naviagate(`/`);
      // dispatch(
      //   addBreadcrumbs([
      //     {
      //       key: 0,
      //       value: "Home",
      //       link: "/",
      //     },
      //     {
      //       key: 1,
      //       value: "Clients",
      //     },
      //   ])
      // );
    }
  };
  const getExtracts = async () => {
    try {
      const response = await apiServices.extract.getExtract();
      const result: any = response.data?.data.filter(
        (item: any) => item.Type === "Condition"
      );

      if (!result.isError) {
        setExtract(result);
      }
    } catch (error) {
      console.error("Failed to fetch extracts:", error);
    }
  };
  useEffect(() => {
    if (selectedTab === TabValues.ExportByCondition) {
      getExtracts();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (
      formSettingsObj &&
      formSettingsObj.data &&
      Object.keys(formSettingsObj.data).length === 0
    ) {
      dispatch(fetchSettings());
    }
  }, [formSettingsObj]);

  useEffect(() => {
    if (formAction != undefined) {
      changePageMode("add");
    }
  }, [formAction]);

  const handleSearchId = (id: any) => {
    setSelectedSearch(id);
    setApplyFilter(true);
  };

  async function fetchClientTypeOptions(): Promise<OptionType[]> {
    const fieldLbl = "client_type:ModifiableList";

    try {
      const res = await apiServices.selectionList.getOptions(fieldLbl);

      if (res.data && res.data.data && res.data.data.length > 0) {
        const options = res.data.data[0].options;

        return options;
      }

      return [];
    } catch (error) {
      console.error("Error fetching client type options:", error);

      return [];
    }
  }

  return (
    <>
      <OverlayLoader isLoading={isLoading} />

      <div className="page-content-area">
        {checkUserPermission(
          loggedUser,
          UserPermissionsList.ALLOW_VIEW_CLIENT
        ) && (
          <>
            <ClientDataList
              page={page}
              changeMode={changePageMode}
              setActiveItem={setActiveItem}
              search={search}
              reloadList={reloadList}
              setReloadList={setReloadList}
              dialogModalOptions={dialogModalOptions}
              setDialogModal={setDialogModal}
              setIsLoading={setIsLoading}
              selectedScope={selectedScope}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              selectedClientType={selectedClientType}
              searchId={selectedSearches}
              setSearchIds={setSearchIds}
              handleCondition={handleCondition}
              searchIds={searchIds}
              selectedTab={selectedTab}
              typeOfExtract={typeOfExtract}
              activeClient={activeClient}
              setSearch={setSearch}
              isLoading={isLoading}
              scopeData={scopeData}
              setSelectedScope={setSelectedScope}
              fetchClientTypeOptions={fetchClientTypeOptions}
              setSelectedClientType={setSelectedClientType}
              setSelectedSearches={setSelectedSearches}
              getExtracts={getExtracts}
              setSelectedTab={setSelectedTab}
              setSelectedSearch={setSelectedSearch}
              setTypeOfExtract={setTypeOfExtract}
              renderedMenuItems={renderedMenuItems}
              selectedSearches={selectedSearches}
              handleAddSearchClick={handleAddSearchClick}
            />
          </>
        )}
        <Drawer
          modalType="non-modal"
          type="overlay"
          separator
          open={isOpen}
          size={drawerSize === "small" ? "large" : "full"}
          position="end"
          style={{
            width: "calc(100vw)",
            height: drawerSize === "small" ? "calc(100vh - 182px)" : "100vh",
            margin: 0,
            padding: 4,
          }}
          onOpenChange={(_, { open }) => setIsOpen(open)}
          className={drawerSize === "small" ? "common-drawer" : ""}
        >
          <DrawerHeader className="drawerHead">
            <DrawerHeaderTitle
              action={
                <Toolbar className={styles.toolbar}>
                  <ToolbarGroup>
                    {drawerSize === "large" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMinimize16Regular />}
                        onClick={() => {
                          setDrawerSize("small");
                        }}
                      />
                    )}
                    {drawerSize === "small" && (
                      <ToolbarButton
                        aria-label="Close panel"
                        appearance="subtle"
                        icon={<FullScreenMaximize16Regular />}
                        onClick={() => {
                          setDrawerSize("large");
                        }}
                      />
                    )}

                    <ToolbarButton
                      aria-label="Close panel"
                      appearance="subtle"
                      icon={<Dismiss16Regular />}
                      onClick={handleCloseAction}
                    />
                  </ToolbarGroup>
                </Toolbar>
              }
            >
              {page && page === "add"
                ? "Add Client"
                : activeClient?.NameAndAddress?.first_names !== "" &&
                  activeClient?.NameAndAddress?.first_names
                ? activeClient?.NameAndAddress?.last_name +
                  ", " +
                  activeClient?.NameAndAddress?.first_names
                : activeClient?.NameAndAddress?.last_name
                ? activeClient?.NameAndAddress?.last_name
                : ""}
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody className="drawerBody">{renderPage(page)}</DrawerBody>
        </Drawer>
        <ModalDialog options={dialogModalOptions} />
      </div>
      <Dialog
        modalType="non-modal"
        open={isDialogOpen}
        onOpenChange={(_, data) => setIsDialogOpen(data.open)}
      >
        <DialogSurface style={{ maxWidth: "75vw" }}>
          <DialogBody>
            <DialogTitle>Saved Searches</DialogTitle>
            <DialogContent>
              <ExtractMain
                setIsDialogOpen={setIsDialogOpen}
                searchId={handleSearchId}
                extract={extract}
                setExtract={setExtract}
                getExtracts={getExtracts}
              />
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default List;
