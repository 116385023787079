import React, { useEffect, useState, useMemo } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Header from "../components/Header";
import "./style.css";
import ModalDialog from "../components/ModalDialog";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { doLogout } from "../redux/authSlice";
import Breadcrumbs from "./breadcrumb/Breadcrumbs";
import Navbar from "./navbar/Navbar";

import Footer from "../components/Footer";
import SystemManagerSidebar from "./sidebar/SystemManagerSidebar";
import Sidebar from "./sidebar/Sidebar";
import { checkUserPermission } from "../utils";
import { UserPermissionsList } from "../config";
import ASCButton from "../components/Button";

type componentProps = {
  [x: string]: any;
};

function ASCLayout(props: componentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const timeout = 1800000;
  const promptBeforeIdle = 10000;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [state, setState] = useState<string>("Active");
  const [count, setCount] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const isUtilites = location.pathname.startsWith("/utilities");
  const isAdminRoute = location.pathname.startsWith("/system-manager");
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
    dispatch(doLogout());
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <>
      <ModalDialog
        options={{
          open: open,
          title: "Your session is about to expire",
          content_line_2:
            "You're being timed out due to inactivity.Please choose to stay logged in or to logoff.",
          content_line_3: "Otherwise, you will logged off automatically.",

          yes: {
            label: `Stay Logged In(${remaining}s)`,
            type: "primary",
            onclick: () => {
              handleStillHere();
            },
          },
          no: {
            label: "Log off",

            onclick: () => {
              dispatch(doLogout());
            },
          },
        }}
      />

      <div className="body-wrapper">
        <div className="page-wrapper">
          <div className="top-header-bar">
            <Header />
          </div>

          <div className={isAdminRoute || isUtilites ? "main-content" : ""}>
            {isAdminRoute && (
              <div className="aside-left">
                <SystemManagerSidebar />
              </div>
            )}
            {/* <div className={isUtilites ? "main-content" : ""}> */}
            {isUtilites && (
              <div className="aside-left">
                <Sidebar />
              </div>
            )}
            {/* </div> */}
            <div className="content">
              <Navbar />
              <div className="content-area">
                <div className="breadcrumbs-and-button">
                  <Breadcrumbs />
                  {["/", "clients", "/clients"].includes(location.pathname) && (
                    <>
                      {checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_VIEW_CLIENT
                      ) && (
                        <ASCButton
                          shape="rounded"
                          label="Add Client"
                          className="asc-button-primary"
                          appearance="primary"
                          onItemClick={() => {
                            navigate(`/clients/add`);
                          }}
                          size="medium"
                        />
                      )}
                    </>
                  )}
                </div>
                <Outlet />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}

export default ASCLayout;
